import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Template } from '../models/template.model';
import { TemplateRequest } from '../models/template-request.model';
import { ApplicationTemplateRequest } from '../models/application-template-request.model';
import { CreateInputRequest } from '../models/create-input-request.model';

@Injectable({
    providedIn: 'root',
})
export class TemplateService {
    constructor(private httpClient: HttpClient) {}

    public getAllTemplates(): Observable<Template[]> {
        return this.httpClient.get<Template[]>(`templates`);
    }

    public getAllTemplatesOnSale(): Observable<Template[]> {
        return this.httpClient.get<Template[]>(`templates/onsale/public`);
    }

    public isApplicationConfigComplete(idApplication: string): Observable<any> {
        return this.httpClient.get(`templates/complete/${idApplication}`);
    }

    public getTemplateRequests(applicationId: string): Observable<ApplicationTemplateRequest[]> {
        let params = new HttpParams();
        if (applicationId) {params = params.set('applicationId', applicationId);}
        params = params.set('details', true)
        return this.httpClient.get<ApplicationTemplateRequest[]>(`templates/template-requests`, {params});
    }

    public createInput(createInputRequest: CreateInputRequest) {
        return this.httpClient.post(`templates/input`, createInputRequest);
    }
}

import { Injectable } from '@angular/core';
import { PlatformService } from './plateform.service';
import jwt_decode from 'jwt-decode';

class LocalStorage implements Storage {
  [name: string]: any;
  readonly length: number;
  clear(): void {}
  getItem(key: string): string | null {
    return null;
  }
  key(index: number): string | null {
    return null;
  }
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements Storage {
  constructor(private plateformService: PlatformService) {
    if (this.plateformService.isPlatformBrowser()) {
      this.storage = new LocalStorage();
      this.storage = localStorage;
    }
  }

  [name: string]: any;
  length: number;
  key(index: number): string {
    throw new Error('Method not implemented.');
  }

  getUser(): any | null {
    if (this.getToken()) {
      return {
        userId: this.getSubject(),
        role: this.getRole(),
        token: this.getToken(),
      };
    }
    return null;
  }

  loggedIn(): boolean {
    if (this.plateformService.isPlatformBrowser()) {
      return !!this.storage.getItem('token');
    } else {
      console.warn('Local Storage not available in the Server');
      return null;
    }
  }

  getRole(): any {
    return this.getPayload().auth;
  }

  getSubject(): any {
    // sub --> userId
    return this.getPayload()?.sub;
  }

  getPayload(): any {
    if (this.getToken()) {
      return jwt_decode(this.getToken());
    }
    return {};
  }

  isAdmin(): boolean {
    return this.getRole() === 'ROLE_ADMIN';
  }

  setToken(token: string): void {
    if (this.plateformService.isPlatformBrowser()) {
      this.storage.setItem('token', token);
    } else {
      console.warn('setToken : Local Storage not available in the Server');
    }
  }

  clear(): void {}

  deleteToken(): void {
    if (this.plateformService.isPlatformBrowser()) {
      this.storage.removeItem('token');
    } else {
      console.warn('clear : Local Storage not available in the Server');
    }
  }

  getToken(): string | null {
    if (this.plateformService.isPlatformBrowser()) {
      return this.storage.getItem('token');
    } else {
      console.warn('getToken : Local Storage not available in the Server');
      return null;
    }
  }

  setObject(key: string, value: any): void {
    if (this.plateformService.isPlatformBrowser()) {
      this.storage.setItem(key, JSON.stringify(value));
    } else {
      console.warn('setObject : Local Storage not available in the Server');
    }
  }

  setObjectWithExpirity(key: string, value: string, expiryInMillis: number) {
    const now = new Date().getTime();
    const item = {
      value: value,
      expiry: now + expiryInMillis,
    };

    if (this.plateformService.isPlatformBrowser()) {
      localStorage.setItem(key, JSON.stringify(item));
    } else {
      console.warn('setObject : Local Storage not available in the Server');
    }
  }

  getObjectWithExpiry(key: string): string | null {

    if (this.plateformService.isPlatformBrowser()) {
        const itemStr = this.storage.getItem(key);

        // If the item doesn't exist, return null
        if (!itemStr) {
          return null;
        }
    
        const item = JSON.parse(itemStr);
        const now = new Date().getTime();
    
        // Check if the item has expired
        if (now > item.expiry) {
          localStorage.removeItem(key);
          return null;
        }
    
        return item.value;

      } else {
        console.warn('getObject : Local Storage not available in the Server');
        return null;
      }
  }

  getObject(key: string): any {
    if (this.plateformService.isPlatformBrowser()) {
      return JSON.parse(this.storage.getItem(key));
    } else {
      console.warn('getObject : Local Storage not available in the Server');
      return null;
    }
  }

  setItem(key: string, value: any): void {
    if (this.plateformService.isPlatformBrowser()) {
      this.storage.setItem(key, value);
    } else {
      console.warn('setItem : Local Storage not available in the Server');
    }
  }

  getItem(key: string): any {
    if (this.plateformService.isPlatformBrowser()) {
      return this.storage.getItem(key);
    } else {
      console.warn('getItem : Local Storage not available in the Server');
      return null;
    }
  }

  removeItem(key: string): void {
    if (this.plateformService.isPlatformBrowser()) {
      return this.storage.removeItem(key);
    } else {
      console.warn('removeItem : Local Storage not available in the Server');
      return null;
    }
  }
}

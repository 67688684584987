import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './local-storage.service';
import { PlatformService } from './plateform.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private translate: TranslateService,
    private platformService: PlatformService,
    private localStorageService: LocalStorageService) { }

  setLanguage(lang: string) {
    this.translate.use(lang);
    if(this.platformService.isPlatformBrowser()){
      this.localStorageService.setItem('selectedLanguage', lang);
    }
  }

  getLanguage(): string {
    return this.translate.getDefaultLang();
  }

  // setInitialAppLanguage() {
  //   if(this.platformService.isPlatformServer()) {
  //     this.translate.use("fr");
  //     return;
  //   } else {
  //     const savedLanguage = this.localStorageService.getItem('selectedLanguage');
  //     if (savedLanguage) {
  //       this.translate.use(savedLanguage);
  //     } else {
  //       this.translate.use(this.translate.getBrowserLang());
  //     }
  //   }
  // }
}

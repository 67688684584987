import { createAction, props } from "@ngrx/store";
import { TemplateRequestEntry } from "src/app/models/template-request-entry.model";
import { Step } from "src/app/services/order-local-storage.service";
import { ApplicationStep } from "./models/application-step.model";
import { DomainStep } from "./models/domain-step.model";
import { OptionsStep } from "./models/options-step.model";
import { AppCreationOrder } from "./models/creation-order.model";


export const setApplicationStep = createAction(
    '[Order] Application step ',
    props<{ applicationStep: ApplicationStep }>()
);

export const setApplicationStepSuccess = createAction(
    '[Order] Application step Success ',
    props<{ applicationStep: ApplicationStep }>()
);

export const loadApplicationStep = createAction('[Order] Load Application');

export const loadApplicationStepSuccess = createAction(
    '[Order] Load Application  Success',
    props<{ applicationStep: ApplicationStep }>()
);

export const setDomainStep = createAction(
    '[Order] Domain step ',
    props<{ domainStep: DomainStep }>()
);

export const setDomainStepSuccess = createAction(
    '[Order] Domain step Success ',
    props<{ domainStep: DomainStep }>()
);

export const loadDomainStep = createAction('[Order] Load Domain');

export const loadDomainStepSuccess = createAction(
    '[Order] Load Domain Success',
    props<{ domainStep: DomainStep }>()
);

export const setOptionsStep = createAction(
    '[Order] Options step ',
    props<{ optionsStep: OptionsStep }>()
);

export const setOptionsStepSuccess = createAction(
    '[Order] Options step Success ',
    props<{ optionsStep: OptionsStep }>()
);

export const loadOptionsStep = createAction('[Order] Load Options');

export const loadOptionsStepSuccess = createAction(
    '[Order] Load Options Success',
    props<{ optionsStep: OptionsStep }>()
);

export const setStep = createAction('[Order] Set Step', props<{ step: Step }>());

export const setStepSuccess = createAction('[Order] Set Step Success', props<{ step: Step }>());

export const setTemplateType = createAction('[Order] Set Template Type', props<{ templateType: string }>());

export const setTemplateTypeSuccess = createAction('[Order] Set Template Type Success', props<{ templateType: string }>());

export const loadTemplateType = createAction('[Order] Load Template Type');

export const loadTemplateTypeSuccess = createAction(
    '[Order] Load Template Type Success',
    props<{ templateType: string }>()
);

export const clearCart = createAction('[Order] Clear cart');

export const clearCartSuccess = createAction('[Order] Clear cart success');

export const setCreationOrder = createAction('[Order] Set Creation Order', props<{ creationOrder: AppCreationOrder }>());

export const setCreationOrderSuccess = createAction('[Order] Set Creation Order Success', props<{ creationOrder: AppCreationOrder }>());

export const clearCreationOrder = createAction('[Order] Clear Creation Order');

export const clearCreationOrderSuccess = createAction('[Order] Clear Creation Order Success');